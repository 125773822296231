import React from 'react'
import ModalVideo from 'react-modal-video'
import "./modal.css";
import "react-modal-video/css/modal-video.min.css";
import play from "./modal-play.svg"

class Modal extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    render() {
        return (
            <React.Fragment>
                <ModalVideo channel="custom" url={"./tqvideo.mp4"} isOpen={this.state.isOpen} onClose={() => this.setState({isOpen: false})}/>
                <div onClick={this.openModal} className="modal-video-button">
                    <span className="modal-video-play"><img width="48" height="60" src={play} alt=""/></span>
                </div>
            </React.Fragment>
        )
    }
}

export default Modal;