import React from "react";
import {AnimationOnScroll} from "react-animation-on-scroll";
import "./contact.css";
import {Form} from "../hubspot/formsubmitv3";
import logo from "../header/img/trust-quotient-logo.svg";

export const Contact = (props) => {
    return (
        <div id="contact">
            <div className="container">
                <div className="col-md-8 col-md-offset-2">
                    <div className="row">
                        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true}>
                            <div className="section-title">
                                <h2>Get In Touch</h2>
                                <p>
                                    Please fill out the form below to send us an email and we will
                                    get back to you as soon as possible.
                                </p>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                            <Form/>
                        </AnimationOnScroll>
                    </div>
                </div>
                <div className="col-md-3 col-md-offset-1 contact-info">
                </div>
            </div>
            <div className="container">
                <div className="social col-md-8 col-md-offset-2">
                    <div className="row">
                        <h3>Contact Info</h3>
                        <ul className="row contact__items">
                            <li className="contact__item contact__link">
                                <p>
                                    <a className="in-btn" href="https://www.linkedin.com/company/tq.life/" target="_blank">
                                    <span><i className="fa fa-linkedin"></i></span>
                                        <span><img width="150" height="auto" src={logo} alt="TQ"/></span>
                                    </a>
                                </p>
                            </li>
                            <li className="contact__item">
                                <p>
                                    <a className="email-btn" href="mailto: david@tq.life" target="_blank">
                                        <span><i className="fa fa-envelope-o"></i></span>
                                        <span>Email</span>
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
