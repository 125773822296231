import React, {useState, useEffect} from "react";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "animate.css/animate.min.css";
import {Navigation} from "./components/navigation/navigation";
import {Header} from "./components/header/header";
import {About} from "./components/about/about";
import {Video} from "./components/video/video";
import {Team} from "./components/team/team";
import {Contact} from "./components/contact/contact";
import {Footer} from "./components/footer/footer";
import {Algorithm} from "./components/algorithm";
import {Compare} from "./components/compare";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    offset: 0
});

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div className={"page"}>
            <Navigation data={landingPageData.Navigation}/>
            <Header/>
            <main>
                <About/>
                <Algorithm/>
                <Video data={landingPageData.Video}/>
                <Compare/>
                <Team/>
                <Contact/>
            </main>
            <Footer/>
        </div>
    );
};

export default App;
