import React from "react";
import {Button} from "../buttons/button";
import "./header.css";
import logo from "./img/trust-quotient-logo.svg";
import bg from "./img/bg-v2.png";
import Modal from "../video/modal"

export const Header = (props) => {
    return (
        <header id="header" className="header">
            <div className="bg">
                <picture>
                {/*    <source className="bg__image" media="(max-width:319px)" srcSet="img/header/bg-320.webp" type="image/webp" width="100%"*/}
                {/*            height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:319px)" srcSet="img/header/bg-320.jpg" width="100%" height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:480px)" srcSet="img/header/bg-480.webp" type="image/webp" width="100%"*/}
                {/*            height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:480px)" srcSet="img/header/bg-480.jpg" width="100%" height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:768px)" srcSet="img/header/bg-768.webp" type="image/webp" width="100%"*/}
                {/*            height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:768px)" srcSet="img/header/bg-768.jpg" width="100%" height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:1024px)" srcSet="img/header/bg-1024.webp" type="image/webp" width="100%"*/}
                {/*            height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:1024px)" srcSet="img/header/bg-1024.jpg" width="100%" height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:1200px)" srcSet="img/header/bg-1200.webp" type="image/webp" width="100%"*/}
                {/*            height="100%"/>*/}
                {/*    <source className="bg__image" media="(max-width:1200px)" srcSet="img/header/bg-1200.jpg" width="100%" height="100%"/>*/}
                {/*    <source className="bg__image" media="(min-width:1201px)" srcSet="img/header/bg-big.webp" type="image/webp" width="100%"*/}
                {/*            height="100%"/>*/}
                {/*    <source className="bg__image" media="(min-width:1201px)" srcSet="img/header/bg-big.jpg" width="100%" height="100%"/>*/}
                    <img className="bg__image" src={bg} width="100%" height="100%" alt=""/>
                </picture>
            </div>
            <div className="header__logo">
                <img width="100%" height="auto" src={logo} alt="TQ"/>
            </div>
            <div className="header__intro">
                <div className="container">
                    <div className="header__column header__column--left">
                        <div className="header__content ">
                            <div className="content__text">
                                <h2>Leads shaped by trust</h2>
                                {/*<h2><img width="150" height="auto" src={logo} alt="TQ"/> <span>plugin</span></h2>*/}
                                <p className="header__desc">
                                    <b>TQ</b> is a lead qualification and prioritization scoring technology specifically designed for the multifamily industry,
                                    enabling you to deal with the most qualified leads FIRST and fill vacant units quickly and efficiently saving up
                                    to 80% of management manhours. TQ integrates with the management team's CRM system.
                                </p>
                                <Button href="https://calendly.com/tq-david/30min"/>
                            </div>
                            <div className="content__bg"></div>
                        </div>
                    </div>
                    <div className="header__column header__column--right">
                        <Modal/>
                    </div>
                </div>
            </div>
        </header>
    );
};
