import React from 'react';
import img from "./about.webp";
import "./about.css";

export const About = (props) => {

    return (
        <section id="about">
            <div className="about__image"
                 style={{
                     backgroundImage: `url("${img}")`,
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     backgroundRepeat: "no-repeat"
            }}>
                <div className="picture"></div>
            </div>
            <div className="about__content">
                <div className="about-text">
                    <h2>Your Benefits</h2>
                    <div className="line"></div>
                    <div className="list-style">
                        <div className="list">
                            <ul className="list__column">
                                <li><i className="fa fa-check"></i><p><b>Focus</b> on the best 20% of the leads</p></li>
                                <li><i className="fa fa-check"></i><p>Save up to 80% of <b>working hours and budget</b>
                                </p></li>
                                <li><i className="fa fa-check"></i><p>100% of your leads get <b>qualified and
                                    prioritized</b> instantly before reaching your CRM</p></li>
                                <li><i className="fa fa-check"></i><p>Streamlined <b>easier and faster deal closing</b>
                                </p></li>
                                <li><i className="fa fa-check"></i><p>Lead score and data based on AI-empowered
                                    algorithm seamlessly <b>integrated</b> to your CRM</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
