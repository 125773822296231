import {useState} from "react";
import React from "react";
import "./formsubmitv3.css";

const hubApiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBAPI_PORTAL_ID}/${process.env.REACT_APP_HUBAPI_FORM_ID}`
const initialState = {
    name: "",
    email: "",
    message: "",
};
let requestBody = {
    "fields": [
        {
            "objectTypeId": "0-1",
            "name": "firstname",
            "value": ""
        },
        {
            "objectTypeId": "0-1",
            "name": "lastname",
            "value": ""
        },
        {
            "objectTypeId": "0-1",
            "name": "email",
            "value": "",
        },
        {
            "objectTypeId": "0-1",
            "name": "message",
            "value": ""
        }
    ]
}
export const Form = (props) => {
    const [{firstname, lastname,  email, message}, setState] = useState(initialState);

    const setRequestBody = (name, value) => {
        requestBody.fields.map(element => {
            if (element.name === name) {
                return element.value = value;
            }
        });
    }

    const clearRequestBody = () => {
        requestBody.fields.map(element => {
            return element.value = "";
        });
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState((prevState) => ({...prevState, [name]: value}));
    };

    const clearState = () => {
        setState(initialState);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setRequestBody("firstname", firstname);
        setRequestBody("lastname", lastname);
        setRequestBody("email", email);
        setRequestBody("message", message);

        await fetch(hubApiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(
            (response) => {
                if (response.status === 200) {
                    console.log("The message has been sent.");
                    clearState();
                    clearRequestBody();
                } else if (response.status === 400) {
                    console.log("Something went wrong. Response status 400");
                } else if (response.status === 403) {
                    console.log("Something went wrong. Response status 403");
                } else if (response.status === 404) {
                    console.log("Something went wrong. Response status 404");
                }
            },
            (error) => {
                console.log("Something went wrong. The message has not been sent");
            }
        )
    }

    return (
        <form id="formsubmit" name="sentMessage" validate onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input type="text" id="firstname" name="firstname" className="form-control"
                               placeholder="First name" required value={firstname} onChange={handleChange}/>
                        <p className="help-block text-danger"></p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input type="text" id="lastname" name="lastname" className="form-control"
                               placeholder="Last name" required value={lastname} onChange={handleChange}/>
                        <p className="help-block text-danger"></p>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <input type="email" id="email" name="email" className="form-control"
                               placeholder="Email" required value={email} onChange={handleChange}/>
                        <p className="help-block text-danger"></p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <textarea name="message" id="message" className="form-control" rows="4"
                          placeholder="Message" required value={message} onChange={handleChange}></textarea>
                <p className="help-block text-danger"></p>
            </div>
            <div id="success"></div>
            <button type="submit" className="btn btn-custom btn-lg">Send Message</button>
        </form>
    );
};