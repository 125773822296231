import React from "react";
import "./style.css";

export const Compare = (props) => {
    return (
        <section id="compares">
            <div className="container">
                <div className="compares">
                    <div className="compares__title">
                        <h2>Boost your productivity with tq</h2>
                        <div className="line"></div>
                        <p className="sub-title"><span>Average vacant units needed to fill: <span className="bold">8 units/month</span></span>
                        </p>
                    </div>
                    <ul className="list">
                        <li className="item item--1">
                            <div className="compare">
                                <div className="compare__icon">
                                    <div className="compare__icon-inner">
                                        <div className="compare__svg">
                                            <img src="./img/compare/1.svg" alt="" width="100%" height="auto"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="compare__inner">
                                    <div className="compare__desc">
                                        <h3><span>Average lead-to-lease conversion</span></h3>
                                    </div>
                                    <div className="compare__desc">
                                        <div className="compare__desc-inner">
                                            <p><span className="margin">Today’s market:</span> <span>4%</span></p>
                                            <p><span className="bold">TQ:</span> <span className="bold">20%</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="item item--2">
                            <div className="compare">
                                <div className="compare__inner">
                                    <div className="compare__icon">
                                        <div className="compare__icon-inner">
                                            <div className="compare__svg">
                                                <img src="./img/compare/2.svg" alt="" width="100%" height="auto"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="compare__desc">
                                        <h3><span>Leads needed to fill 8 units</span></h3>
                                    </div>
                                    <div className="compare__desc">
                                        <div className="compare__desc-inner">
                                            <p><span className="margin">Today’s market:</span> <span>200</span></p>
                                            <p><span className="bold">TQ:</span> <span className="bold">40</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="item item--3">
                            <div className="compare">
                                <div className="compare__inner">
                                    <div className="compare__icon">
                                        <div className="compare__icon-inner">
                                            <div className="compare__svg">
                                                <img src="./img/compare/3.svg" alt="" width="100%" height="auto"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="compare__desc">
                                        <h3><span>Hours needed to deal with 200 leads</span></h3>
                                    </div>
                                    <div className="compare__desc">
                                        <div className="compare__desc-inner">
                                            <p><span className="margin">Today’s market:</span> <span>40</span></p>
                                            <p><span className="bold">TQ:</span> <span className="bold">8</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <p className="compares__source">*Today's market figures are taken from knockcrm.com</p>
                </div>
            </div>
        </section>
    );
};
