import React from "react";
import "./video.css";
import {AnimationOnScroll} from "react-animation-on-scroll";

export const Video = (props) => {
    return (
        <section id="video" className="text-center">
            <div className="container">
                <div className="row">
                    <h2>{props.data ? props.data.title : ""}</h2>
                    <div className="line"></div>
                    {/*<p>{props.data ? props.data.paragraph : ""}</p>*/}
                    <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
                        <div className="video">
                            <video width="100%" height="auto" controls>
                                <source src="./tqvideo.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </section>
    );
};



