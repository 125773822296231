import React from "react";
import {AnimationOnScroll} from "react-animation-on-scroll";
import teamJpg from "./img/Picture6.webp";
import "./team.css";

export const Team = (props) => {
    return (
        <div id="team" className="text-center">
            <div className="container">
                <div className="team">
                    <div className="row">
                        <div className="thumbnail col-xs-12 col-xs-offset-0 col-sm-12 col-sm-offset-0 col-md-4 col-md-offset-0">
                            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                <img src={teamJpg} className="team-img" alt="..." width="240" height="247"/>
                                <div className="caption">
                                    <h3>David S. Duek</h3>
                                </div>
                            </AnimationOnScroll>
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-0">
                            <div className="section-title">
                                <h2>Talk to an expert</h2>
                                <div className="line"></div>
                            </div>
                            <p className="team__desc">
                                David, an architect with over 20 years of experience in the real estate market,
                                has a wealth of knowledge and expertise to offer. He has extensive hands-on experience
                                in the real estate industry, with a specific focus on multifamily projects.
                                Leverage it to your benefit.
                            </p>
                            <div className="team__button">
                                <a href="#contact" className="team__contact">Let's talk!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
