import React from "react";
import "./button.css";

export const Button = (props) => {
    return (
        <a href={props.href ? props.href : ""} target="_blank" className="btn btn-custom btn-lg page-scroll">
            <i className="fa fa-play"></i>
            <span>Schedule a demo</span>
        </a>
    );
}