import React from "react";
import "./style.css";

export const Algorithm = () => {
    return (
        <section id="algorithm">
            <div className="container">
                <h2 className="title">Optimize your processes</h2>
                <div className="line"></div>
                <p className="sub-title"><b>TQ</b> will enable you to deal with the best leads first, by qualifying and prioritizing all your leads. You’ll see them scored at a glance in your favorite CRM while optimizing and focusing on the top 20% of leads. This can save up to 80% of your management team’s time and budget.</p>
                <div className="algorithm">
                    <div className="algorithm__bg"><img src={"img/algorithm/bg.svg"} alt={""} width={"100%"} height={"auto"}/></div>
                    <ul>
                        <li>
                            <div className="algorithm__icon algorithm__icon--1">
                                <div className="algorithm__svg"><img src={"img/algorithm/step-1.svg"} alt={""} width={"100%"} height={"auto"}/>
                                </div>
                            </div>
                            <div className="algorithm__step algorithm__step--1">
                                <h2 className="algorithm__name algorithm__name--1 algorithm__name--screen"><span className="algorithm--color-1">Marketing</span></h2>
                                <p className="algorithm__desc">Project publicity in the different ILS’s and Digital campaigns</p>
                            </div>
                            {/*<h2 className="algorithm__name algorithm__name--1 algorithm__name--screen"><span className="algorithm--color-1">Marketing</span></h2>*/}
                        </li>
                        <li>
                            <div className="algorithm__icon algorithm__icon--2">
                                <div className="algorithm__svg"><img src={"img/algorithm/step-2.svg"} alt={""} width={"100%"} height={"auto"}/>
                                </div>
                            </div>
                            <div className="algorithm__step algorithm__step--2">
                                <h2 className="algorithm__name algorithm__name--2 algorithm__name--screen"><span className="algorithm--color-2">Scored leads</span></h2>
                                <p className="algorithm__desc">Leads get qualified, prioritized and scored by our AI powered algorithm before they reach your CRM</p>
                            </div>
                            {/*<h2 className="algorithm__name algorithm__name--2 algorithm__name--screen"><span className="algorithm--color-2">Scored leads</span></h2>*/}
                        </li>
                        <li>
                            <div className="algorithm__icon algorithm__icon--3">
                                <div className="algorithm__svg"><img src={"img/algorithm/step-3.svg"} alt={""} width={"100%"} height={"auto"}/>
                                </div>
                            </div>
                            <div className="algorithm__step algorithm__step--3">
                                <h2 className="algorithm__name algorithm__name--3 algorithm__name--screen"><span className="algorithm--color-3">CRM integration</span></h2>
                                <p className="algorithm__desc">Lead score and data are seamlessly integrated into the client's CRM.</p>
                            </div>
                            {/*<h2 className="algorithm__name algorithm__name--3 algorithm__name--screen"><span className="algorithm--color-3">CRM integration</span></h2>*/}
                        </li>
                        <li>
                            <div className="algorithm__icon algorithm__icon--4">
                                <div className="algorithm__svg"><img src={"img/algorithm/step-4.svg"} alt={""} width={"100%"} height={"auto"}/>
                                </div>
                            </div>
                            <div className="algorithm__step algorithm__step--4">
                                <h2 className="algorithm__name algorithm__name--4 algorithm__name--screen"><span className="algorithm--color-4">Lead Management</span></h2>
                                <p className="algorithm__desc">The management team focuses on the top 20% of leads</p>
                            </div>
                            {/*<h2 className="algorithm__name algorithm__name--4 algorithm__name--screen"><span className="algorithm--color-4">Lead Management</span></h2>*/}
                        </li>
                        <li>
                            <div className="algorithm__icon algorithm__icon--5">
                                <div className="algorithm__svg"><img src={"img/algorithm/step-5.svg"} alt={""} width={"100%"} height={"auto"}/>
                                </div>
                            </div>
                            <div className="algorithm__step algorithm__step--5">
                                <h2 className="algorithm__name algorithm__name--5 algorithm__name--screen"><span className="algorithm--color-5">Closing</span></h2>
                                <p className="algorithm__desc">Time and money are save by faster and easier closing</p>
                            </div>
                            {/*<h2 className="algorithm__name algorithm__name--5 algorithm__name--screen"><span className="algorithm--color-5">Closing</span></h2>*/}
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};
