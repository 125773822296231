import React from "react";
import "./navigation.css";
import {ScheduleButton} from "../buttons/schedule";
import logo from "./img/trust-quotient-logo.svg";

export const Navigation = (props) => {
    return (
        <nav id="menu" className="navbar navbar-default navbar-sticky-top">
            <div className="container navbar__panel">
                <div className="row">
                    <div className="col-xs-4 col-xs-offset-0 col-sm-6 col-sm-offset-3 col-md-7 col-md-offset-3">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#bs-navbar-menu">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar--1"></span>
                            <span className="icon-bar icon-bar--2"></span>
                            <span className="icon-bar icon-bar--3"></span>
                        </button>
                        <div className="navbar-collapse navbar__menu collapse" id="bs-navbar-menu">
                            <ul className="nav navbar-nav navbar-right">
                                {props.data
                                    ? props.data.menu.map((d, i) => (
                                        <li key={`${d.href}-${i}`}>
                                            <a href={d.href} className="page-scroll">
                                                {d.item}
                                            </a>
                                        </li>
                                    ))
                                    : ""}
                            </ul>
                        </div>
                    </div>
                    <div className="navbar__logo col-xs-4 col-xs-offset-0 col-sm-3 col-sm-offset-0 col-md-2 col-md-offset-0">
                        <div className="navbar-header">
                            <a className="navbar-brand page-scroll" href="#page-top">
                                <img width="100%" height="auto" src={logo} alt="TQ"/>
                            </a>
                        </div>
                    </div>
                    <div className="navbar__button col-xs-4 col-xs-offset-0 col-sm-3 col-sm-offset-0 col-md-2 col-md-offset-0">
                        <ScheduleButton/>
                    </div>
                </div>
            </div>
        </nav>
    );
};
