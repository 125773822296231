import React from "react";
import "./footer.css";


export const Footer = (props) => {
    let newDate = new Date()
    let year = newDate.getFullYear()
    return (
        <footer id="footer">
            <div className="container text-center">
                <p>
                    {`© Copyright ${year}. All rights reserved.`} Trust Quotient
                </p>
            </div>
        </footer>
    );
};
